<template>
  <iframe v-on:load="calcHeight('header')" id="header" scrolling="no"  width="100%"></iframe>

  <div class="pt-lg-12 pb-lg-3 pt-8 pb-6">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <h2 class="mb-0 text-center">{{ this.settings.home_combo_text }}</h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-3" v-for="(combo, index) in mySchool.combos.data" :key="'combo' + index">
          <div class="card  mb-4 card-hover">
            <router-link :to="{name: 'ComboDetail', params: {code: combo.code}}" class="card-img-top">
              <img :src="combo.thumbnail.link" alt="" class="rounded-top-md card-img-top" v-if="combo.thumbnail">
            </router-link>
            <div class="card-body">
              <h4 class="mb-2">
                <router-link :to="{name: 'ComboDetail', params: {code: combo.code}}" class="card-img-top">
                  {{ combo.name }}
                </router-link>
              </h4>
              <div class="lh-1">
                                    <span>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning"></i>
                  </span>
                <span class="text-warning">4.5</span>&nbsp;
                <span class="fs-6 text-muted">(7,700)</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <h2 class="mb-0 text-center">{{ this.settings.home_course_text }}</h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-3" v-for="(course, index) in mySchool.courses.data" :key="'course' + index">
          <div class="card  mb-4 card-hover">
            <router-link :to="{name: 'CourseDetail', params: {code: course.code}}" class="card-img-top">
              <img :src="course.thumbnail.link" alt="" class="rounded-top-md card-img-top" v-if="course.thumbnail !== null">
            </router-link>
            <div class="card-body">
              <h4 class="mb-2">
                <router-link :to="{name: 'CourseDetail', params: {code: course.code}}" class="text-inherit">
                  {{ course.name }}
                </router-link>
              </h4>
              <div class="lh-1">
                                    <span>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning"></i>
                  </span>&nbsp;
                <span class="text-warning">4.5</span>
              </div>
            </div>
            <div class="card-footer" v-if="course.teachers && course.teachers.length">
              <div class="row align-items-center g-0">
                <div class="col-auto">
                  <img :src="course.teachers[0].thumbnail ? course.teachers[0].thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="rounded-circle avatar-xs" />
                </div>
                <div class="col ms-2">
                  <span>{{ course.teachers[0].name }}</span>
                </div>
                <div class="col-auto">
                  <a href="#" class="text-muted bookmark">
                    <i class="fe fe-bookmark  "></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <iframe v-on:load="calcHeight('footer')" id="footer" scrolling="no" width="100%"></iframe>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {getUser, saveSchool} from "@/core/services/jwt.service";

export default {
  name: 'Index',
  components: {

  },
  data() {
    return {
      mySchool: {
        name: "",
        code: "",
        thumbnail: {
          link: ""
        },
        courses: {
          data: []
        },
        combos: {
          data: []
        }
      },
      settings: {
        header_html: "",
        footer_html: "",
        home_combo_text: "",
        home_course_text: ""
      }
    }
  },
  methods: {
    loadSchool() {
      let query = `query {
        mySchool {
          code
          name
          content
          thumbnail {
            link
          }
          courses(first: 12, orderBy: {column: "homepage_order", order: ASC}, where: {AND: [{column: "show_homepage", value: 1}]}) {
            data {
              code
              name
              teachers {
                name
                  thumbnail {
                  link
                  media_type
                }
              }
              thumbnail {
                link
                media_type
              }
            }
          }
          combos(first: 12, orderBy: {column: "homepage_order", order: ASC}, where: {AND: [{column: "show_homepage", value: 1}]}) {
            data {
              code
              name
              thumbnail {
                link
                media_type
              }
            }
          }
          metaData(first: 10, where: {AND: [{column: "meta_key", value:["header_html", "footer_html", "home_combo_text", "home_course_text"], operator: IN}]}) {
            data {
              meta_key
              meta_value
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.mySchool) {
              this.mySchool = data.data.mySchool;console.log(this.mySchool.metaData);
              this.saveSchoolLocal();
              document.title = this.mySchool.name;
              if (this.mySchool.metaData && this.mySchool.metaData.data) {
                for (let i = 0; i < this.mySchool.metaData.data.length; i++) {
                  if (this.mySchool.metaData.data[i].meta_value) {
                    this.settings[this.mySchool.metaData.data[i].meta_key] = this.mySchool.metaData.data[i].meta_value.replaceAll(".ladi-lazyload{background-image:none!important}", "");
                    this.settings[this.mySchool.metaData.data[i].meta_key] = this.settings[this.mySchool.metaData.data[i].meta_key].replaceAll(".ladi-lazyload {background-image: none !important", "");
                  }
                }
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    calcHeight(id) {
      let iframe = document.getElementById(id);
      iframe.height= iframe.contentWindow.document.body.scrollHeight;
    },
    renderIframe(id) {
      let iframe = document.getElementById(id);
      if (iframe) {
        let doc = iframe.contentWindow.document;
        doc.open();
        doc.write(this.settings[id + "_html"]);
        doc.close();
      }
    },
    saveSchoolLocal() {
      saveSchool({last_fetch: Math.floor(Date.now() / 1000),
        name: this.mySchool.name,
        code: this.mySchool.code,
        content: this.mySchool.content,
        thumbnail: this.mySchool.thumbnail
      });
    },
    updateMissingInfo() {
      const user = getUser();
      if (user && (!user.name || !user.email || !user.phone)) {
        this.$router.push({name: 'MyAccount', query: {updateMissingInfo: 'yes'}});
      }
    }
  },
  mounted() {
    this.loadSchool();
    this.updateMissingInfo();
  },
  watch: {
    settings: {
      handler() {
        this.renderIframe("header");
        this.renderIframe("footer");
      },
      deep: true
    }
  }
}

</script>
